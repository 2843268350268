import React, { useState } from 'react'
import {
  Container,
  InputContainer,
  MainWrapper,
  StaticAttributeLabel,
  StaticAttribute,
  StaticAttributeContainer,
  Header,
  HeaderTitle,
  ProfileContainer,
} from './styles'

import { useTranslation } from 'react-i18next'
import EditableAccountAttribute from '../../components/organisms/atoms/EditableAttribute'
import { ReactComponent as ProfilePicture } from '../../../../assets/images/profilePicture.svg'
import BrowseContainer from '../../../Browse/components/BrowseContainer'
import { useAppSettings } from '@eassen/business-logic'

const EditProfile: React.FC = () => {
  const { t } = useTranslation()
  const { updateUserProfileForm } = useAppSettings(navigator)
  const { values, handleChange, handleBlur, errors } = updateUserProfileForm
  return (
    <BrowseContainer>
      <MainWrapper>
        <Header>
          <HeaderTitle>{t('settings.account.my_account')}</HeaderTitle>
        </Header>
        <Container>
          <InputContainer>
            <EditableAccountAttribute
              label={t('account.fullName.label')}
              inputProps={{
                value: values.name, //placeholder for now
                onChangeText: handleChange('fullName'),
                onBlur: handleBlur('fullName'),
              }}
            />
            {/* {errors.name && (
                <Text type="small" color="error">
                {errors.name.toString()}
                </Text>
            )} */}
          </InputContainer>
          <InputContainer>
            <EditableAccountAttribute
              label={t('account.email.label')}
              inputProps={{
                value: values.email,
                onChangeText: handleChange('email'),
                onBlur: handleBlur('email'),
              }}
            />
            {/* {errors.name && (
                <Text type="small" color="error">
                {errors.name.toString()}
                </Text>
            )} */}
          </InputContainer>
          <StaticAttributeContainer>
            <StaticAttributeLabel>{t('account.memberSince.label')}</StaticAttributeLabel>
            <StaticAttribute typography={'paragraph_b'}>
              {
                //place holder for now
                //values.memberSince ??
                '10/10/2014'
              }
            </StaticAttribute>
          </StaticAttributeContainer>
          <StaticAttributeContainer>
            <StaticAttributeLabel>{t('account.membership.label')}</StaticAttributeLabel>
            <StaticAttribute typography={'paragraph_b'}>
              {
                //place holder for now
                //values.membership
                'Premium'
              }
            </StaticAttribute>
          </StaticAttributeContainer>
        </Container>
      </MainWrapper>
    </BrowseContainer>
  )
}

export default EditProfile
