import styled from '@emotion/native'
import { ThemeContext } from '@emotion/react'

const SearchBoxWrapper = styled.View(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}))

export const BackWrapper = styled.TouchableOpacity(({ theme }) => ({
  paddingHorizontal: theme.sizes.sm,
}))

export const VoiceWrapper = styled.TouchableOpacity(({theme}) => ({
  paddingHorizontal: theme.sizes.sm
}))

export { SearchBoxWrapper }
