var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeviceInfo from 'react-native-device-info';
import { logout, useAppDispatch, useAppSelector } from '@eassen/store';
import { useFormik } from 'formik';
import { Platform } from 'react-native';
import * as yup from 'yup';
//TODO : use the actual url instead of the localhost one
var WEB_URL = 'http://localhost:3000/';
export var useAppSettings = function (_a) {
    var navigation = _a.navigation;
    var t = useTranslation().t;
    var name = useAppSelector(function (state) { return state.profile; }).name;
    var dispatch = useAppDispatch();
    var appVersion = DeviceInfo.getVersion();
    var _b = useState(), updateUserProfileError = _b[0], setUpdateUserProfileError = _b[1];
    var fullName = useAppSelector(function (state) { return state.profile.name; });
    var email = useAppSelector(function (state) { return state.profile.email; });
    var isAnonymous = useAppSelector(function (state) { return state.appState.appNavigationState; }) === 'ANONYMOUS';
    var sections = useMemo(function () {
        if (isAnonymous) {
            // TODO: MAKE THIS LESS REPETITIVE
            return [
                {
                    title: t('settings.more.title'),
                    data: [
                        {
                            title: t('settings.more.about'),
                            iconName: 'about',
                            onPress: function () { return navigation.navigate('WebView', { url: WEB_URL, title: t('settings.more.about') }); },
                        },
                        {
                            title: t('settings.more.privacy'),
                            iconName: 'privacy',
                            onPress: function () {
                                return navigation.navigate('WebView', {
                                    url: "".concat(WEB_URL, "browse/privacy"),
                                    title: t('settings.more.privacy'),
                                });
                            },
                        },
                        {
                            title: t('settings.more.terms'),
                            iconName: 'terms',
                            onPress: function () {
                                return navigation.navigate('WebView', {
                                    url: "".concat(WEB_URL, "browse/privacy"),
                                    title: t('settings.more.terms'),
                                });
                            },
                        },
                        {
                            title: t('settings.more.help'),
                            iconName: 'help',
                            onPress: function () { return navigation.navigate('WebView', { url: WEB_URL, title: t('settings.more.help') }); },
                        },
                    ],
                },
            ];
        }
        else {
            var Sections = __spreadArray(__spreadArray([
                {
                    title: t('settings.account.my_account'),
                    iconName: 'profile',
                    onPress: function () { return navigation.navigate('EditProfile'); },
                }
            ], (Platform.OS === 'web'
                ? [
                    {
                        title: t('settings.account.payment_methods'),
                        iconName: 'payment',
                        onPress: function () { return navigation.navigate('Wallet'); },
                    },
                ]
                : []), true), [
                {
                    title: t('settings.more.terms'),
                    iconName: 'terms',
                    onPress: function () {
                        return navigation.navigate('WebView', {
                            url: "".concat(WEB_URL, "browse/terms"),
                            title: t('settings.more.terms'),
                        });
                    },
                },
                {
                    title: t('settings.more.privacy'),
                    iconName: 'privacy',
                    onPress: function () {
                        return navigation.navigate('WebView', {
                            url: "".concat(WEB_URL, "browse/privacy"),
                            title: t('settings.more.privacy'),
                        });
                    },
                },
                {
                    title: t('settings.more.logout'),
                    iconName: 'logout',
                    onPress: function () { return dispatch(logout()); },
                },
            ], false);
            return [
                {
                    title: t('settings.account.title'),
                    data: Sections,
                },
            ];
        }
    }, [navigation, isAnonymous]);
    var validationStep2Schema = yup.object().shape({
        fullName: yup.string().required(t('error.email_empty_error')),
        phoneNumber: yup.string().min(8, t('error.phone_number_length')).required(t('error.email_empty_error')),
        memberSince: yup.string().matches(/^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d\d$/, t('error.date')),
    });
    var updateUserProfileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            fullName: fullName,
            email: email,
            memberSince: '',
            membership: '',
        },
        validationSchema: validationStep2Schema,
        validateOnChange: false,
        onSubmit: function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var fullName = _b.fullName, email = _b.email;
            return __generator(this, function (_c) {
                return [2 /*return*/];
            });
        }); },
    });
    return { name: name, sections: sections, appVersion: appVersion, updateUserProfileForm: updateUserProfileForm, updateUserProfileError: updateUserProfileError };
};
