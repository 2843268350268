import React, { useState } from 'react'
import { TextInputProps } from 'react-native'
import { InputWrapper } from './styles'
import { Input } from '@eassen/components'
import { useTheme } from '@emotion/react'
import { ReactComponent as Pencil } from '../../../../../../assets/icons/pencil.svg'
import { ReactComponent as Check } from '../../../../../../assets/icons/check.svg'
import { TouchableOpacity } from 'react-native-gesture-handler'

export interface EditableAccountAttributeProps {
  label?: string
  inputProps: TextInputProps
}

const EditableAccountAttribute: React.FC<EditableAccountAttributeProps> = ({ label, inputProps }) => {
  const theme = useTheme()
  const [isEditing, setIsEditing] = useState(false)

  const toggleEditing = () => {
    setIsEditing(!isEditing)
  }
  const getRightIcon = () => {
    return (
      <TouchableOpacity onPress={toggleEditing}>
        {isEditing ? (
          <Check width={theme.scale(18)} height={theme.scale(30)} fill={theme.colors.foreground0} />
        ) : (
          <Pencil width={theme.scale(30)} height={theme.scale(30)} />
        )}
      </TouchableOpacity>
    )
  }
  return (
    <InputWrapper>
      <Input
        label={label}
        inputProps={{
          ...inputProps,
          editable: isEditing, // Make input editable based on isEditing state
          placeholderTextColor: theme.colors.foreground0,
        }}
        RightComponent={getRightIcon}
        paddingHorizontal={0}
        labelStyle={{
          paddingHorizontal: 0,
          left: 0,
        }}
        style={{ backgroundColor: theme.colors.background0 }}
      />
    </InputWrapper>
  )
}

export default EditableAccountAttribute
