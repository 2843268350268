import React from 'react'
import { ActionsContainer, Button, OverlayView, ModalContainer, Description } from './styles'
import { useTheme } from '@emotion/react'
import { Text } from '@eassen/components'
export interface LogOutModalProps {
  title?: string
  icon?: React.JSX.Element
  description: string
  confirmLabel: string
  cancelLabel: string
  onConfirm: () => void
  onCancel: () => void
}

const LogOutModal: React.FC<LogOutModalProps> = ({
  title,
  icon,
  description,
  confirmLabel,
  cancelLabel,
  onCancel,
  onConfirm,
}) => {
  const theme = useTheme()
  return (
    <OverlayView>
      <ModalContainer>
        {icon ?? null}
        {title && <Text type={'cta_large'}>{title}</Text>}
        <Description type={'H1B'}>{description}</Description>
        <ActionsContainer>
          <Button color={'primary'} onPress={onConfirm}>
            <Text type={'H4'}>{confirmLabel}</Text>
          </Button>
          <Button color={'secondary'} onPress={onCancel}>
            <Text type={'H4'} color={'background0'}>
              {cancelLabel}
            </Text>
          </Button>
        </ActionsContainer>
      </ModalContainer>
    </OverlayView>
  )
}

export default LogOutModal
