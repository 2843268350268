import { TextSizes } from '@eassen/themes'
import styled from '@emotion/styled'

export const MainWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.colors.background0,
  paddingTop: theme.sizes.sm,
  width: '52vw',
}))

export const Container = styled.div(({ theme }) => ({
  paddingTop: theme.sizes.sm,
  width: '100%',
}))

export const UpperContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: theme.sizes.sm,
  width: '100%',
}))

export const InputContainer = styled.div(() => ({
  width: '100%',
}))

export const StaticAttributeLabel = styled.span(({ theme }) => ({
  color: theme.colors.foreground1,
  textTransform: 'uppercase',
  display: 'block', // Ensure proper alignment on web
}))

export const StaticAttribute = styled.span<{ typography: keyof TextSizes }>(({ theme, typography }) => ({
  display: 'block',
  color: theme.colors[theme.fontSizes[typography].color],
  lineHeight: theme.fontSizes[typography].lineHeight,
  letterSpacing: theme.fontSizes[typography].letterSpacing,
  fontSize: theme.fontSizes[typography].fontSize,
  fontFamily: theme.fontSizes[typography].fontFamily,
  fontWeight: '400',
  textAlign: 'left',
}))

export const StaticAttributeContainer = styled.div(({ theme }) => ({
  width: '100%',
  marginBottom: theme.sizes.sm,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.sizes.xxs,
}))

export const Header = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: theme.sizes.md,
  width: '100%',
}))

export const HeaderTitle = styled.h3(({ theme }) => ({
  color: theme.colors.foreground0,
  margin: 0,
}))

export const ProfileContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'center',
}))
