import React, { PropsWithChildren, useMemo } from 'react'
import { ThemeProvider as EmotionProvider } from '@emotion/react'
import { darkTheme, useDimensions } from '@eassen/themes'

const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { scale, selectDeviceType } = useDimensions()
  const theme = useMemo(() => {
    return darkTheme(scale, selectDeviceType)
  }, [scale, selectDeviceType])

  return <EmotionProvider theme={theme}>{children}</EmotionProvider>
}

export default ThemeProvider
