import styled from '@emotion/styled'

export const MainWrapper = styled.div(({ theme }) => ({
  width: '100%',
  height: '100vh',
  backgroundColor: theme.colors.background0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))
