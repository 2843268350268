import React, { useCallback, useState } from 'react'
import { useAppSettings } from '@eassen/business-logic'
import { changeAppState, useAppDispatch } from '@eassen/store'
import {
  Container,
  Header,
  BackArrowWrapper,
  HeaderTitle,
  Section,
  SectionItem,
  IconWrapper,
  ItemTitle,
} from './styles'

import { ReactComponent as UserIcon } from '../../../../assets/icons/user.svg'
import { ReactComponent as CardIcon } from '../../../../assets/icons/card.svg'
import { ReactComponent as LogoutIcon } from '../../../../assets/icons/logout.svg'
import { ReactComponent as Question } from '../../../../assets/icons/question.svg'
import BrowserContainer from '../../components/BrowseContainer'
import LogOutModal from '../../components/LogoutModal'
// Mapa de iconos
const iconMap = {
  profile: UserIcon,
  payment: CardIcon,
  logout: LogoutIcon,
}

const WebSettings: React.FC = () => {
  const { sections, appVersion } = useAppSettings({ navigate: (path: string) => console.log(`Navigate to: ${path}`) })
  const dispatch = useAppDispatch()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const handleLogout = useCallback(() => {
    dispatch(changeAppState({ appNavigationState: 'NOT_LOGGED_IN' }))
  }, [dispatch])
  const handleCancelLogout = useCallback(() => {
    setIsModalVisible(false)
  }, [])
  const handleShowLogoutModal = useCallback(() => {
    console.log('showing logout')
    setIsModalVisible(true)
  }, [])

  return (
    <BrowserContainer>
      <Container>
        {/* Header */}
        <Header>
          <HeaderTitle>Ajustes</HeaderTitle>
        </Header>

        {/* Secciones */}
        {sections.map((section: any, sectionIndex: number) => (
          <Section key={sectionIndex}>
            {section.data.map(
              (item: { iconName: keyof typeof iconMap; title: string; onPress: () => void }, itemIndex: number) => {
                const IconComponent = iconMap[item.iconName] || (() => null)

                return (
                  <SectionItem
                    key={`${sectionIndex}-${itemIndex}`}
                    onClick={item.title === 'Cerrar sesión' ? handleShowLogoutModal : item.onPress}>
                    <IconWrapper>
                      <IconComponent />
                    </IconWrapper>
                    <ItemTitle>{item.title}</ItemTitle>
                  </SectionItem>
                )
              },
            )}
          </Section>
        ))}

        {/* LogOut modal */}
        {isModalVisible && (
          <LogOutModal
            icon={<Question />}
            description="¿Estás seguro de que deseas cerrar sesión?"
            confirmLabel="Si"
            cancelLabel="No"
            onConfirm={handleLogout}
            onCancel={handleCancelLogout}
          />
        )}
      </Container>
    </BrowserContainer>
  )
}

export default WebSettings
