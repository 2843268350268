import React, { useRef } from 'react'
import { TextInput, TextInputProps, View, ViewStyle } from 'react-native'
import { StyledLabelView, StyledInput, StyledInputContainer, StyledInputIconContainer, StyledTextInput } from './style'
import { useTheme } from '@emotion/react'
import { TextSizes } from '@eassen/themes'
import Text from '../../atoms/Text'

export interface InputProps {
  label?: string
  labelStyle?: ViewStyle
  icon?: React.JSX.Element
  style?: ViewStyle
  textType?: keyof TextSizes
  paddingVertical?: ViewStyle['paddingVertical']
  paddingHorizontal?: ViewStyle['paddingHorizontal']
  inputProps?: TextInputProps
  borderColor?: ViewStyle['borderColor']
  RightComponent?: React.FC
  RightLabelComponent? : React.FC //the button for editing is on the label, like on the webapp for editing attributes
}

const Input: React.FC<InputProps> = ({ label, labelStyle, icon, style, textType = 'paragraph_b', inputProps, borderColor, RightComponent, paddingVertical, paddingHorizontal, RightLabelComponent}) => {
  const theme = useTheme()
  const inputRef = useRef<TextInput>(null)

  const handleInputTouch = () => {
    inputRef.current?.focus()
  }

  return (
    <View>
      <StyledInput paddingVertical={paddingVertical} borderColor={borderColor} style={style} onTouchStart={handleInputTouch}>
        {label && <StyledLabelView style={labelStyle} >
          <Text type="caption" style={labelStyle}>{label}</Text>
          {RightLabelComponent && <RightLabelComponent />}
        </StyledLabelView>}
        <StyledInputContainer paddingHorizontal={paddingHorizontal}>
          {icon && <StyledInputIconContainer>{icon}</StyledInputIconContainer>}
          <StyledTextInput
            typography={textType}
            ref={inputRef}
            {...inputProps}
            placeholderTextColor="grey"
            selectionColor={theme.colors.primary}
            autoCapitalize="none"
          />
          {RightComponent && <RightComponent />}
        </StyledInputContainer>
      </StyledInput>
    </View>
  )
}

export default Input
