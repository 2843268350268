import styled from '@emotion/styled'

// Contenedor principal
export const Container = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.colors.background0,
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}))

// Encabezado
export const Header = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  marginBottom: theme.sizes.md,
  width: '100%',
}))

export const BackArrowWrapper = styled.div({
  cursor: 'pointer',
  marginRight: 8,
})

export const HeaderTitle = styled.h3(({ theme }) => ({
  color: theme.colors.foreground0,
  margin: 0,
}))

// Secciones
export const Section = styled.div(({ theme }) => ({
  marginBottom: theme.sizes.lg,
  width: '52vw',
}))

export const SectionItem = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.sizes.sm,
  borderBottom: `1px solid ${theme.colors.foreground1}`,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.colors.background1,
  },
}))

export const IconWrapper = styled.div(({ theme }) => ({
  width: theme.sizes.md,
  height: theme.sizes.md,
  marginRight: theme.sizes.sm,
}))

export const ItemTitle = styled.span(({ theme }) => ({
  flex: 1,
  color: theme.colors.foreground0,
}))
