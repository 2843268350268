import styled from '@emotion/native'
import { TextStyle, StyleSheet } from 'react-native'
import LinearGradient from 'react-native-linear-gradient'
import { Colors, Sizes, TextFontWeight, TextSizes } from '@eassen/themes'
import Text from '../Text'
import { TextProps } from '../Text/style'

interface TitleProps {
  type: keyof TextSizes
}

interface ContainerProps {
  verticalPadding?: keyof Sizes
  horizontalPadding?: keyof Sizes
  backgroundColor?: keyof Colors
}

interface IconContainerProps {
  paddingValue?: keyof Sizes
  iconSide: string
}

const fontWeightMapping: Record<TextFontWeight, TextStyle['fontWeight']> = {
  light: '300',
  regular: '400',
  medium: '500',
  semibold: '600',
  bold: '700',
  '100': '100',
  '200': '200',
  '300': '300',
  '400': '400',
  '500': '500',
  '600': '600',
  '700': '700',
  '800': '800',
  '900': '900',
  normal: 'normal',
}

const Container = styled.View<ContainerProps>(({ theme, backgroundColor }) => ({
  width: '100%',
  borderRadius: 2,
  backgroundColor: theme.colors[backgroundColor ?? 'background3'] as string,
  padding: 1,
  flexDirection: 'column',
  justifyContent: 'center',
}))

const Title = styled(Text)<TitleProps>(({ theme, type }) => ({
  paddingBottom: type ? theme.sizes.xxxs : 0,
}))

export const Overlay = styled(LinearGradient)(() => ({
  ...StyleSheet.absoluteFillObject,
}))

const InputWrapper = styled.TextInput<TextProps & ContainerProps>(
  ({ theme, type, color, verticalPadding, horizontalPadding, backgroundColor }) => ({
    flex: 1,
    color: theme.colors[color ?? theme.fontSizes[type].color] as keyof Colors,
    fontSize: theme.fontSizes[type].fontSize,
    fontFamily: theme.fontFamilies[theme.fontSizes[type].fontFamily],
    fontWeight: fontWeightMapping[theme.fontSizes[type].fontWeight],
    backgroundColor: theme.colors[backgroundColor ?? 'background3'] as string,
    letterSpacing: theme.fontSizes[type].letterSpacing,
    paddingVertical: theme.sizes[verticalPadding ?? 'xxs'],
    paddingHorizontal: theme.sizes[horizontalPadding ?? 'sm'],
  }),
)

const InputContainer = styled.View<ContainerProps>(({ theme, backgroundColor }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: theme.colors[backgroundColor ?? 'background3'] as string,
}))

const IconWrapper = styled.View<IconContainerProps>(({ theme, iconSide, paddingValue }) => ({
  paddingLeft: iconSide === 'left' ? theme.sizes[paddingValue ?? 'xxxs'] : 0,
  paddingRight: iconSide === 'right' ? theme.sizes[paddingValue ?? 'xxxs'] : 0,
}))

const IconWrapperTouchable = styled.TouchableOpacity<IconContainerProps>(({ theme, iconSide, paddingValue }) => ({
  paddingLeft: iconSide === 'left' ? theme.sizes[paddingValue ?? 'xxxs'] : 0,
  paddingRight: iconSide === 'right' ? theme.sizes[paddingValue ?? 'xxxs'] : 0,
}))

export { Container, InputWrapper, Title, InputContainer, IconWrapper , IconWrapperTouchable}
