import styled from '@emotion/native'
import { AppBackground, Text } from '@eassen/components'
import { TextProps } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { ScreenType } from '@eassen/themes'

export const Container = styled(KeyboardAwareScrollView)()

export const MainWrapper = styled(AppBackground)<{ screenType: ScreenType }>(({ theme, screenType }) => ({
  padding: theme.sizes.md,
  flex: 1,
  maxWidth: 450,
  height: screenType === 'Small' ? '100%' : 'auto',
  borderWidth: screenType === 'Small' ? 0 : 3,
  borderColor: theme.colors.background1,
  backgroundColor: screenType === 'Small' ? theme.colors.background0 : theme.colors.background2,
  borderRadius: theme.sizes.md,
}))

export const UpperContainer = styled.View(({ theme }) => ({
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
}))

export const LowerContainer = styled.View(({ theme }) => ({
  justifyContent: 'flex-end',
  paddingBottom: 1,
  flex: 1,
}))

export const TermsConditionsContainer = styled.View(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: theme.sizes.sm,
  gap: theme.sizes.sm,
}))

export const TermsConditionsText = styled(Text)<TextProps>({
  flexWrap: 'wrap',
  textAlign: 'center',
})

export const ResendOtp = styled.TouchableOpacity(({ theme }) => ({
  alignSelf: 'flex-start',
}))

// Stepper
export const PageStepperContainer = styled.View(({ theme }) => ({
  flexDirection: 'column',
  justifyContent: 'flex-start',
  paddingVertical: theme.sizes.md,
  gap: theme.sizes.md,
  flex: 1,
}))

export const Stepper = styled.View(({ theme }) => ({
  flex: 1,
  alignSelf: 'stretch',
}))

export const StepperIndicator = styled.View<{ active?: Boolean }>(({ theme, active }) => ({
  width: theme.sizes.xs,
  height: theme.sizes.xs,
  borderRadius: theme.sizes.sm,
  backgroundColor: active ? theme.colors.primary : theme.colors.background0,
}))

export const Subtitle = styled(Text)({
  alignSelf: 'center',
})

export const CheckboxError = styled(Text)(({ theme }) => ({
  paddingBottom: theme.sizes.sm,
}))
