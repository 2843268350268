import React, { FC } from 'react'
import { ReactComponent as Envelope } from '../../../../assets/icons/envelope.svg'
import { ReactComponent as Lock } from '../../../../assets/icons/lock.svg'
import {
  Container,
  Header,
  TextContainer,
  FormsContainer,
  ActionsContainer,
  ButtonContainer,
  InputContainer,
  MainWrapper,
  IconButton,
  RegisterText,
} from './styles'
import { Keyboard, TouchableOpacity, TouchableWithoutFeedback } from 'react-native'
import { useLogin } from '@eassen/business-logic'
import { useTranslation } from 'react-i18next'
import { Button, Input, LoginScreenTemplate, Text } from '@eassen/components'
import { useTheme } from '@emotion/react'
import AuthContainer from '../../components/AuthContainer'
import { useDimensions } from '@eassen/themes'

const LoginScreen: FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const onSuccess = () => {
    //navigation.navigate('BottomTabs', { screen: 'Home' })
  }

  const { formik, apiError } = useLogin({ onSuccess })
  const { values, errors, handleChange, handleBlur, handleSubmit } = formik
  const onForgotPasswordPress = () => {
    //navigation.navigate('ForgotPassword')
  }

  const onSignUpPress = () => {
    //navigation.navigate('Register')
  }

  const onBackPress = () => {
    //navigation.goBack()
  }

  const onFocus = () => {
    // setApiError('')
  }

  return (
    <AuthContainer>
      <LoginScreenTemplate
        envelopeIcon={<Envelope width={theme.sizes.lg} height={theme.sizes.lg} />}
        lockIcon={<Lock width={theme.sizes.lg} height={theme.sizes.lg} />}
        onForgotPasswordPress={onForgotPasswordPress}
        formValues={{ values, errors, handleChange, handleBlur, handleSubmit, onFocus }}
        apiError={apiError}
        onSignUpPress={onSignUpPress}
      />
    </AuthContainer>
  )
}

export default LoginScreen
