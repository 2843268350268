import React, { useMemo } from 'react'
import { View, I18nManager, StyleProp, ViewStyle } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { SvgProps } from 'react-native-svg'

export interface Svgprops extends SvgProps {
  Icon: React.FC<SvgProps> | React.JSX.Element
  isRTL?: 'RTL' | 'LTR' | 'DEFAULT'
  viewStyle?: StyleProp<ViewStyle>
}

const Svg: React.FC<Svgprops> = ({ Icon, isRTL = 'DEFAULT', viewStyle, ...extraProps }) => {
  const rtlEnabled = useMemo(() => {
    switch (isRTL) {
      case 'RTL':
        return true
      case 'DEFAULT':
        return I18nManager.isRTL
      default:
        return false
    }
  }, [isRTL])
  const renderIcon = () => { 
    if (typeof Icon === 'function') { // If it's a React.FC, call it with propsconst 
      const Component = Icon as React.FC<SvgProps>; 
      return <Component {...extraProps} />; 
    } 
    return Icon; 
    };
  return (
    <View testID="SvgTest" style={[viewStyle, { transform: [{ rotateY: rtlEnabled ? '180deg' : '0deg' }] }]}>
      {renderIcon()}
    </View>
  )
}

export default Svg
