import styled from '@emotion/native'
import { Text } from '@eassen/components'
import { Colors } from '@eassen/themes'
import { Touchable, TouchableOpacityProps } from 'react-native'

export const OverlayView = styled.View(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
}))

export const ModalContainer = styled.View(({ theme }) => ({
  width: '40%',
  height: '42%',
  maxWidth: 400,
  maxHeight: 300,
  padding: theme.sizes.xxxs,
  backgroundColor: theme.colors.background0,
  borderRadius: theme.sizes.sm,
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.3)',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 24,
}))

export const Button = styled.TouchableOpacity<TouchableOpacityProps & { color?: keyof Colors }>(({ theme, color }) => ({
  padding: theme.sizes.xxs,
  backgroundColor: color ? theme.colors[color] : theme.colors.primary,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 6,
  height: 40,
  width: 100,
}))

export const ActionsContainer = styled.View(() => ({
  flexDirection: 'row',
  alignContent: 'center',
  justifyContent: 'center',
  gap: 24,
  width: '100%',
}))

export const Description = styled(Text)(({ theme }) => ({
  paddingHorizontal: theme.sizes.xxs,
  color: theme.colors.foreground0,
  textAlign: 'center',
  fontFamily: 'Inter',
}))
