import React from 'react';
import { 
  ButtonContainer, 
  Container, 
  InputContainer, 
  MainWrapper, 
  ContentWrapper,
  Title 
} from './styles';
import { ReactComponent as Envelope } from '../../../../assets/icons/envelope.svg';
import { FormikValues } from 'formik';
import { Button, Input, Text } from '@eassen/components';
import { useTranslation } from 'react-i18next';
import { useForgotPassword } from '@eassen/business-logic';
import { useTheme } from '@emotion/react';
import { useDimensions } from '@eassen/themes';
import AuthContainer from '../../components/AuthContainer';

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { screenType } = useDimensions();

  const onBack = () => {
    // navigation.goBack();
  };

  const sendPasswordResetEmail = (formValues: FormikValues) => {
    // navigation.navigate('EnterNewPassword', { email: formValues.email });
  };

  const {
    formik: { values, errors, handleChange, handleBlur, handleSubmit },
    apiError,
  } = useForgotPassword({
    sendPasswordResetEmail,
  });

  return (
    <AuthContainer>
      <MainWrapper screenType={screenType}>
        <Container screenType={screenType}>
          <ContentWrapper>
            <Title>{t('resetpassword.title')}</Title>
            <InputContainer>
              <Input
                icon={<Envelope width={theme.sizes.lg} height={theme.sizes.lg} />}
                inputProps={{
                  value: values.email,
                  onChangeText: handleChange('email'),
                  onBlur: handleBlur('email'),
                  placeholder: t('email.placeholder'),
                }}
              />
              {errors.email && (
                <Text type="small" color="error">
                  {errors.email.toString()}
                </Text>
              )}
              {apiError && (
                <Text type="small" color="error">
                  {apiError}
                </Text>
              )}
            </InputContainer>
            <ButtonContainer>
              <Button
                title={t('resetpassword.reset')}
                size="block"
                textType="cta_large"
                borderRadius={8}
                color="primary"
                onPress={handleSubmit}
              />
            </ButtonContainer>
          </ContentWrapper>
        </Container>
      </MainWrapper>
    </AuthContainer>
  );
};

export default ResetPassword;
