import React, { useEffect } from 'react';
import './style.css';

// Importación de componentes
import Header from '../../components/Header';

const Search: React.FC = () => {
    const navLinks = [
        { text: "Inicio", href: "/browse/home" },
        { text: "Mis cursos", href: "/browse/my-courses" },
        { text: "Ajustes", href: "/browse/settings" }
    ];

    return (
        <div className="homepage">
            {/*====== Scroll To Top Area End ======*/ }
            <Header 
                logo="/landingPublic/img/logo-white.png"
                navLinks={navLinks}
            />
            <div className="mainSearch">
                {/* <ReviewSection /> */}
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                This is the Search Page.
            </div>
        </div>
    );
};

export default Search;