import React, { useEffect } from 'react'
import './App.css'
import { Provider } from 'react-redux'
import { store, persistor, initLocalization } from '@eassen/store'
import Router from './navigations/AppNavigator'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { SafeAreaProvider, initialWindowMetrics } from 'react-native-safe-area-context'
import ThemeProvider from './providers/ThemeProvider'
import { Host } from 'react-native-portalize'

const A = () => {
  return <div style={{ height: '100vh', width: '100vw', backgroundColor: 'red' }} />
}

const App: React.FC = () => {
  useEffect(() => {
    ;(async () => {
      await initLocalization(__DEV__)
    })()
  }, [])
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <SafeAreaProvider initialMetrics={initialWindowMetrics}>
          <ThemeProvider>
            <Host>
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            </Host>
          </ThemeProvider>
        </SafeAreaProvider>
      </PersistGate>
    </Provider>
  )
}
export default App
