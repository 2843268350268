import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from '../pages/Home'
import Search from '../pages/Search'
import WebSettings from '../pages/Settings'
import SearchScreen from '../pages/Search'
import EditProfile from '../../Auth/pages/EditProfile'

const CommonGroup: React.FC = () => {
  return (
    <Routes>
      <Route path="home" element={<Home />} />
      <Route path="search" element={<Search />} />
      <Route path="settings" element={<WebSettings />} />
      {/*<Route path="search" element={<SearchScreen />}></Route>*/}
      
      {/* Just for testing the prifile page is also hare*/}
      <Route path="profile" element={<EditProfile />} />

      <Route path="*" element={<Navigate to="/common/not-found" />} />
    </Routes>
  )
}

export default CommonGroup