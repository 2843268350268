import styled from '@emotion/styled';
import { AppBackground } from '@eassen/components';
import { ScreenType } from '@eassen/themes';

export const MainWrapper = styled(AppBackground)<{screenType: ScreenType}>(({ theme, screenType }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  backgroundColor: theme.colors.background0,
}));

export const Container = styled.div<{screenType: ScreenType}>(({screenType, theme}) => ({
  maxWidth: '450px',
  width: '100%',
  margin: '0 auto',
  padding: theme.sizes.xl,
  backgroundColor: 'rgba(38, 38, 38, 0.8)',
  borderRadius: theme.sizes.md,
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.12)',
}));

export const UpperContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.sizes.lg,
  marginBottom: theme.sizes.xl,
}));

export const InputContainer = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.sizes.sm,
}));

export const ButtonContainer = styled.div(({ theme }) => ({
  marginTop: theme.sizes.xl,
  width: '100%',
}));

export const ContentWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
}));

export const Title = styled.h1(({ theme }) => ({
  color: '#FFFFFF',
  fontSize: theme.sizes.xl,
  marginBottom: theme.sizes.lg,
  textAlign: 'center',
  fontWeight: 'normal',
}));