import React, { useState, useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import './style.css';

import { ReactComponent as NotFound } from '../../../../assets/images/not-found.svg'

interface NavLink {
  text: string;
  href: string;
}

interface HeaderProps {
  logo: string;
  navLinks: NavLink[];
}

const Header: React.FC<HeaderProps> = ({ logo, navLinks }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isListening, setIsListening] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { t } = useTranslation();

  const previousPath = useMemo(() => {
    return location.pathname === '/browse/search' ? '/browse/home' : location.pathname;
  }, [location]);

  const recognition = useMemo(() => {
    if ('webkitSpeechRecognition' in window) {
      const SpeechRecognition = new (window as any).webkitSpeechRecognition();
      SpeechRecognition.lang = 'es-ES';
      SpeechRecognition.continuous = true;
      SpeechRecognition.interimResults = false;
      return SpeechRecognition;
    }
    return null;
  }, []);

  const startVoiceRecognition = useCallback(() => {
    if (recognition) {
      recognition.start();
      setIsListening(true);
    }
  }, [recognition]);

  const stopVoiceRecognition = useCallback(() => {
    if (recognition) {
      recognition.stop();
      setIsListening(false);
    }
  }, [recognition]);

  if (recognition) {
    recognition.onresult = (event: { results: any[] }) => {
      const result = event.results[event.results.length - 1][0].transcript.trim();
      setSearchTerm(result);
    };

    recognition.onerror = () => {
      stopVoiceRecognition();
    };

    recognition.onend = () => {
      setIsListening(false);
    };
  }

  const handleSearchClick = () => {
    setIsSearchOpen(true);
    navigate('/browse/search');
  };

  const handleCloseSearch = () => {
    setIsSearchOpen(false);
    setSearchTerm('');
    navigate(previousPath);
  };

  const handleProfileClick = () => {
    navigate('/browse/profile');
  };

  const NoResultsFound = () => (
    <div className="no-results">
      <NotFound className="no-results-icon" />
      <h3 className="no-results-title">
        No hay resultados para "{searchTerm}"
      </h3>
      <p className="no-results-subtitle">
        Intente buscar algo más o busque cursos y materiales de tendencia y recomendados
      </p>
    </div>
  );

  React.useEffect(() => {
    if (location.pathname === '/browse/search') {
      setIsSearchOpen(true);
    } else {
      setIsSearchOpen(false);
    }
  }, [location.pathname]);

  return (
    <>
      <header className="header">
        <div className="header-container">
          {!isSearchOpen ? (
            <>
              <div className="left-section">
                <div className="logo-container">
                  <img src={logo} alt="Logo" className="logo" />
                </div>
                
                <nav className="nav-links">
                  {navLinks.map((link, index) => (
                    <a 
                      key={index} 
                      href={link.href} 
                      className={index === 0 ? 'nav-link active' : 'nav-link'}
                    >
                      {link.text}
                    </a>
                  ))}
                </nav>
              </div>

              <div className="header-actions">
                <button className="search-button" onClick={handleSearchClick} aria-label="Search">
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <circle cx="11" cy="11" r="8"/>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"/>
                  </svg>
                </button>
                <button 
                  className="profile-button" 
                  onClick={handleProfileClick} 
                  aria-label="Profile"
                >
                  <img src="/pfp.svg" alt="Profile" className="profile-icon" />
                </button>
              </div>
            </>
          ) : (
            <div className="search-container">
              <button className="back-button" onClick={handleCloseSearch}>
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path d="M19 12H5M12 19l-7-7 7-7"/>
                </svg>
              </button>

              <div className="search-input-container">
                <svg className="search-icon" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <circle cx="11" cy="11" r="8"/>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"/>
                </svg>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Buscar..."
                  className="search-input"
                  autoFocus
                />
                {searchTerm && (
                  <button className="clear-button" onClick={() => setSearchTerm('')}>
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                      <path d="M18 6L6 18M6 6l12 12"/>
                    </svg>
                  </button>
                )}
                <button 
                  className={`voice-button ${isListening ? 'listening' : ''}`}
                  onClick={isListening ? stopVoiceRecognition : startVoiceRecognition}
                >
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                    <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"/>
                    <path d="M19 10v2a7 7 0 0 1-14 0v-2M12 19v4M8 23h8"/>
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
      </header>
      {isSearchOpen && searchTerm && (
        <div className="search-results-container">
          <NoResultsFound />
        </div>
      )}
    </>
  );
};

export default Header;